import moment from "moment";
import { POST } from "./requests";
export const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const GetInRangeAddress = (address, type) =>
  type === "Delivery"
    ? address.filter((x) => GetDistance(x.location) < 40)
    : address;

export function GetDistance(loc, unit) {
  try {
    const lat2 = GetCookieValue("lat"),
      lat1 = loc.latitude;
    const lon2 = GetCookieValue("lon"),
      lon1 = loc.longitude;
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  } catch {}
}

export const GetCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const momentLocalDate = (d) => {
  return moment.utc(d).local();
};

export const LocalDate = (d, f = "LLL") => moment(d).utc().format(f);

export const wishListAction = async (path, id) => {
  const formData = new FormData();
  formData.append("id", id);
  return await POST("/wishlist" + path, formData, false);
};
// export const GetLocalMomentDate = (d) => moment(moment.utc(d).toDate()).local();
