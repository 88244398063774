import Link from "next/link";
import { IKImage } from "imagekitio-react";
import { ImageKit_Product_uri } from "../../utils/static";
import { AiFillHeart } from "react-icons/ai";
const SingleProduct = ({ width = "19%", ...props }) => {
  const { price, mrp, id, title, brand } = props;

  const duration = price?.duration ? (
    <p className="font-lg text-info mb-0">
      {price.duration.days} days{" "}
      {price.duration.hours > 0 ? <>and {price.duration.hours} hrs</> : null}
    </p>
  ) : null;

  const off = ((price.amount - mrp) / mrp) * 100;

  return (
    <div
      className="single-product-view mb-3"
      style={{
        width,
      }}
    >
      <Link href={"/product/" + id}>
        <a href={"/product/" + id} className="single-product ">
          <div className="img-wrapper mb-3">
            <div className="wishlist-button shadow-sm" role="button">
              <AiFillHeart size={17} color="grey" />
            </div>
            <IKImage
              urlEndpoint={ImageKit_Product_uri}
              transformation={[
                {
                  height: 320,
                  width: 230,
                  bg: "F7F7F7",
                  focus: "auto",
                  // blur: "10",
                  cropMode: "pad_resize",
                },
              ]}
              className="rounded-8"
              loading="lazy"
              lqip={{ active: true, quality: 20 }}
              path={id + "/0.jpg"}
            />
          </div>
          <div className="content">
            <h2 className="mb-0">{brand}</h2>
            <p className="title mb-0">{title}</p>
            {duration}
            <div className="d-flex price align-items-center">
              <p>$ {price.amount}</p>
              {off < 0 ? (
                <>
                  <p className="text-decoration-line-through fw-normal text-grey font-md mx-2">
                    $ {mrp}
                  </p>
                  <span className="text-danger font-md">
                    ( {Math.round(off)}% OFF)
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default SingleProduct;
