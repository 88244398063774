import { Button, Col, Container, Row } from "react-bootstrap";
import SingleProduct from "./product";
import Link from "next/link";
import { SearchLoading } from "../shared/loading";
import { withRouter } from "next/router";
import { useEffect, useState } from "react";
import { Line } from "../shared/common";
import CustomDropdown from "../shared/dropdown";
import { MdClose } from "react-icons/md";
import { AiOutlineClear } from "react-icons/ai";
import dynamic from "next/dynamic";

const NoMoreResult = dynamic(() => import("./noresult"));

const ProductContent = ({
  items,
  hasmore,
  loading,
  router,
  SortMenu,
  FilterItems,
  children,
}) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(JSON.parse(router.query.filter || "[]"));
  }, [router.query]);

  const Push = (e) => router.push({ query: { ...router.query, ...e } });

  const ClearFilter = (key, value) => {
    filters[key] = value;
    Push({ filter: JSON.stringify(filters) });
  };

  const Filter = ({ id }) => {
    const options = FilterItems.find((s) => s.id === id).options;

    return filters[id]?.map((s) => {
      const item = options.find(
        (f) => JSON.stringify(f.value) === JSON.stringify(s)
      );

      return (
        <Col xs="auto mb-3">
          <div
            style={{ whiteSpace: "nowrap" }}
            className="px-3 py-2 rounded-7 border font-md"
          >
            {item.title}
            <MdClose
              role="button"
              onClick={() =>
                ClearFilter(
                  id,
                  filters[id].filter((c) => c !== s)
                )
              }
              className="ms-2 text-danger"
            />
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="search-main-content" id="search-content">
      <Row className="mb-4">
        <Col>
          <Row>
            {Object.keys(filters).map((s) => (
              <Filter id={s} />
            ))}
            {Object.keys(filters).length > 0 ? (
              <Col
                role="button"
                onClick={() => Push({ filter: "" })}
                className="text-danger font-md ms-3 py-2 px-3"
              >
                <span>
                  <AiOutlineClear /> Clear All
                </span>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col sm="auto">
          <CustomDropdown
            variant={"bg-light shadow-none font-md border py-2 rounded-8 px-4"}
            onChange={(e) => Push({ sort: e })}
            items={SortMenu}
            title={
              "Sort By : " +
              (SortMenu.find((s) => router.query.sort == s.value)?.label ||
                "Popularity")
            }
          ></CustomDropdown>
        </Col>
      </Row>
      <Line />
      <Container>
        {children || (
          <Row>
            {items?.map((x) => (
              <SingleProduct width="24%" {...x} />
            ))}
            {loading === true ? (
              <SearchLoading count={8} sm="3 my-4" />
            ) : hasmore ? null : (
              <NoMoreResult />
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default withRouter(ProductContent);
