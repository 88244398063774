import { useState, useEffect } from "react";
import { GET } from "../../../utils/requests";
import { Feature } from "../../shared/common";
import { CustomInput, InputLayout, SubmitButton } from "../../shared/input";
import { TwoBoxLoading } from "../../shared/loading";
import FormSubmit from "../../shared/submit";

const TransferRental = ({ id }) => {
  const [data, setData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (_) => {
    setData((await GET("/account/rental/UpdateTransfer/" + id)) || {});
  };

  if (!data) return <TwoBoxLoading />;

  if (data.order)
    return (
      <FormSubmit url={"/account/rental/TransferPicked/" + id} next="?refresh">
        <Feature>
          <div className="w-100">
            <p className="font-lg mb-1 mt-2">
              <span className="fw-600">{data.order.name}</span>, Will Pick Item
              From Location, You Can Reach Them Too ,
            </p>
            <p className="fw-500 text-danger">Tel : {data.order.mobile}</p>

            <InputLayout className="">
              <CustomInput title="Pickup Code From Buyer" name="code" />
            </InputLayout>
            <SubmitButton variant="primary mb-2" title={"Order Completed"} />
          </div>
        </Feature>
      </FormSubmit>
    );

  //No New Order

  return (
    <FormSubmit url={`/account/rental/CancelTransfer/` + id} next="?refresh">
      <Feature>
        <div>
          <p>
            {data.hoursPassed > 24 ? (
              "No Order Recieved To Pickup From Your Location! Kindly Cancel The Waiting And Return Item To Original Owner"
            ) : (
              <>
                <span className="text-danger fw-600">
                  {(24 - data.hoursPassed).toFixed(0)} hrs
                </span>{" "}
                Left To Wait For Somebody To Pickup, Or You Can Cancel Waiting
                And Return Item To Original Owner Right Now
              </>
            )}
          </p>
          <SubmitButton
            variant="danger"
            title="Cancel Waiting And Return Item"
          />
        </div>
      </Feature>
    </FormSubmit>
  );

  return <div>{}</div>;
};

export default TransferRental;
