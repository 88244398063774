import { useState } from "react";
import { Dropdown } from "react-bootstrap";

const CustomDropdown = ({
  className = "",
  title,
  variant,
  onChange,
  children,
  items,
  ...props
}) => {
  const [show, setShow] = useState(true);
  return (
    <Dropdown
      className={`custom-dropdown ${className}`}
      // onMouseLeave={() => setShowDropdown(false)}
      // onMouseOver={() => setShowDropdown(true)}
      // show={showDropdown}
    >
      <Dropdown.Toggle variant={variant}>{title}</Dropdown.Toggle>

      <Dropdown.Menu className="py-3">
        {children ||
          items?.map((x) => (
            <Dropdown.Item onClick={(e) => onChange(x.value)}>
              {x.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
