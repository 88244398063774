import moment from "moment";
import { withRouter } from "next/router";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { MdCalendarToday } from "react-icons/md";
import { GET } from "../../../utils/requests";
import {
  GetLocalMomentDate,
  LocalDate,
  momentLocalDate,
} from "../../../utils/utility";
import { Feature } from "../../shared/common";
import { CustomInput, InputLayout, SubmitButton } from "../../shared/input";
import CustomModal from "../../shared/modal";
import OptionView from "../../shared/option";
import FormSubmit from "../../shared/submit";
import TransferRental from "./transfer";

const RentalAction = (props) => {
  const { status, events } = props;
  const [show, setShow] = useState();
  if (
    status !== "Active" ||
    events.find((s) => (s.type === "Delivered") & (status === true)) ||
    props.type !== "Tool_Rental"
  )
    return null;

  return (
    <>
      <Col sm="auto">
        <div role="button" onClick={(e) => setShow(true)}>
          <span className={`fw-bold font-lg text-primary`}>{"Rental"}</span>
        </div>
      </Col>
      <CustomModal
        className="light-modal"
        size="md"
        show={show}
        close={(_) => setShow(false)}
      >
        <ReturnInitiate {...props} close={(e) => setShow(false)} />
      </CustomModal>
    </>
  );
};

const ReturnInitiate = ({
  events = [],
  id,
  price,
  router,
  close,
  transfer,
  product,
}) => {
  if (!price) return null;

  const { transferUserId, recieverUserId } = product;

  console.log("events", events);
  const nextEvent = events.find((s) => s.status === "Pending");
  const startTime = momentLocalDate(
    events.find((s) => s.type === "Delivered").date
  );
  const endTime = momentLocalDate(
    events.find((s) => s.type === "Return_Initiated").date
  );
  const totalHours = endTime.diff(startTime, "minutes");
  const rate = price.amount / price.duration.totalMinutes;
  // console.log(rate, totalHours, startTime, endTime);
  console.log(startTime.format("LLL"));
  const options = [
    {
      title: "Let Some Other Buyer Pick From My Location",
      value: true,
    },
    {
      title: "I will return item to original owner",
      value: false,
    },
  ];
  //;

  if (!events.find((s) => s.type === "Delivered").date) {
    if (
      events.find(
        (x) => (x.type === "Waiting_Confirmation") & (x.status === "Completed")
      )
    )
      return (
        <Feature>
          <div className="w-100">
            <p className="fw-600">Product Confirmation</p>
            <p>I Acknowledge Product Condition To Be</p>
            <Row>
              <Col>
                <FormSubmit
                  url={
                    `/account/rental/CompleteTransfer/` + id + "?valid=false"
                  }
                  next="?refresh"
                >
                  <SubmitButton title="Defective" variant="danger" />
                </FormSubmit>
              </Col>
              <Col>
                <FormSubmit
                  url={`/account/rental/CompleteTransfer/` + id + "?valid=true"}
                  next="?refresh"
                >
                  <SubmitButton title="Alright" variant="success" />
                </FormSubmit>
              </Col>
            </Row>
          </div>
        </Feature>
      );
    return (
      <NoAction close={close}>
        <span className="fw-600 text-blue font-md">
          {price?.type === "Delivery"
            ? "Wait For Product To Be Delivered"
            : "Please Pickup The Item From Pickups Location"}
        </span>
      </NoAction>
    );
  }

  ///Completed
  if (!nextEvent)
    return (
      <NoAction close={close}>
        <span className="text-success fw-600">
          {" "}
          Summary :{" "}
          <span className="text-blue">{(totalHours / 60).toFixed(1)} hrs </span>
        </span>
      </NoAction>
    );
  console.log(transfer, "dd");

  if (nextEvent.type === "Completed") {
    if (transferUserId) {
      const hours = moment().diff(endTime, "minutes");
      return <Transfer id={id} pId={product.id} hours={hours} />;
    } else
      return (
        <NoAction close={close}>
          <span className="fw-600 text-primary font-lg">
            Waiting For Product Acknowledgment
          </span>
        </NoAction>
      );
  }

  //initiating return
  if (nextEvent?.type === "Return_Initiated")
    return (
      <FormSubmit url={"/account/order/ReturnItem/" + id} next="?refresh">
        <Feature>
          <span className="fw-600 mx-auto tex">
            In use since{" "}
            <span className="text-danger">{startTime.fromNow()}</span>
          </span>
        </Feature>
        {price?.type === "Local_Pickup" ? (
          <InputLayout>
            <CustomInput title="Mode">
              <Form.Select name="transfer">
                {options.map((s, i) => (
                  <option value={s.value}>{s.title}</option>
                ))}
              </Form.Select>
            </CustomInput>
          </InputLayout>
        ) : null}
        <Form.Check
          className="font-sm"
          id="check"
          required
          label="I Acknowledge Item Is Not Damaged"
        />
        <SubmitButton title="Initiate Return" />
        {price?.type === "Local_Pickup" ? (
          <p className="font-tiny my-3">
            If No Buyer Come To Pickup Within 1 day, Then You Will Have To
            Return Item To Seller, Just If You Choose 'Let Somebody Pickup'
            Option
          </p>
        ) : null}
      </FormSubmit>
    );

  return null;
};

const NoAction = ({ children, close }) => (
  <div className="flex-between">
    {children}
    <div>
      <SubmitButton title="Back" variant="dark" onClick={close} />
    </div>
  </div>
);

const Transfer = ({ id, pId, hours }) => {
  return <TransferRental id={id} />;
  console.log("tra");
  const [data, setData] = useState();
  useEffect(async () => {
    setData(await GET("/account/rental/UpdateTransfer/" + id));
  }, []);

  if (!data) return <p>Loading ...</p>;

  if (data?.data?.id === id) {
    return (
      <FormSubmit url={`/account/rental/CancelTransfer/` + id} next="?refresh">
        <Feature>
          <div>
            <p>
              {hours > 10 ? (
                "No Order Recieved To Pickup From Your Location! Kindly Cancel The Waiting And Return Item To Original Owner"
              ) : (
                <>
                  <span className="text-danger fw-600">
                    {(24 - hours).toFixed(1)} hrs
                  </span>{" "}
                  Left To Wait For Somebody To Pickup, Or You Can Cancel Waiting
                  And Return Item To Original Owner Right Now
                </>
              )}
            </p>
            <SubmitButton
              variant="danger"
              title="Cancel Waiting And Return Item"
            />
          </div>
        </Feature>
      </FormSubmit>
    );
  }

  const { address } = data;
  console.log(data);

  if (
    data.data.events.find(
      (x) => (x.type === "Waiting_Confirmation") & (x.status === "Completed")
    )
  )
    return (
      <Feature>
        <p>
          Waiting For Buyers Confirmation, <br /> Ask {address.name} To Confirm
          Product
        </p>
      </Feature>
    );
  return (
    <FormSubmit url={"/account/rental/TransferPicked/" + data.data.id}>
      <Feature>
        <div className="w-100">
          <p className="font-lg mb-1 mt-2">
            <span className="fw-600">{address?.name}</span>, Will Pick Item From
            Location, You Can Reach Them Too ,
          </p>
          <p className="fw-500 text-danger">Tel : {address?.mobile}</p>

          <InputLayout className="">
            <CustomInput title="Pickup Code From Buyer" name="code" />
          </InputLayout>
          <SubmitButton variant="primary mb-2" title={"Order Completed"} />
        </div>
      </Feature>
    </FormSubmit>
  );

  return null;
};

export default RentalAction;
