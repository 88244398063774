import { Col, Container, Row } from "react-bootstrap";


export const ShrimBox = ({ style = { height: '20vh' }, className, ...props }) => (
    <div {...props} className={`w-100 shimmer-animate mb-3 ${className}`} style={style} />);
export const ShrimLine = ({ className, ...props }) =>
(<div className={`shimmer-line shimmer-line-br shimmer-line-full shimmer-animate ${className}`} {...props}>

</div>);



export const TwoBoxLoading = () => <Row>
    <Col sm='4'>
        <ShrimBox />
    </Col>
    <Col sm='8'>
        <ShrimLine className={'py-5'} />
    </Col>
</Row>

export const MultiLineLoading = ({ count = 4, ...props }) => [...Array(count).keys()]
    .map(x => <ShrimLine className={'py-4 mb-3'} {...props} />)

export const SearchLoading = ({ count = 4, ...props }) => <Row>
    {
        [...Array(count).keys()].map(x =>
            <Col {...props}>
                <ShrimBox style={{ height: '40vh' }} />
                <Col xs='3'>
                    <ShrimLine/>
                </Col>
                <ShrimLine className={'py-2'} />
            </Col>
        )
    }
</Row>

export const ProductLoading = () => <Container>
    <Row>
        <Col sm='12 mb-4'>
            <Row>
                <Col sm='2'><ShrimLine className={'py-2'} /></Col>
                <Col sm='3'><ShrimLine className={'py-2'} /></Col>
            </Row>
        </Col>
        <Col sm='6'>
            <ShrimBox style={{ height: '60vh', marginBottom: '30vh !important' }} />
        </Col>
        <Col>
            <Row>
                <Col sm='3'>
                    <ShrimLine className={'py-2'} />
                </Col>
                <Col sm='12'>
                    <ShrimLine className={'py-3 mb-4'} />
                </Col>
                <Col>
                    <Row>
                        <Col sm='5'>
                            <ShrimLine className={'py-4'} />
                        </Col>
                        <Col sm='5'>
                            <ShrimLine className={'py-4'} />
                        </Col>
                        <Col sm='2'>
                            <ShrimLine className={'py-4'} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </Row>
</Container>