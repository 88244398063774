


import { useRouter, withRouter } from "next/router";
import { Col, Row } from "react-bootstrap";
import CustomModal from "./modal";
import { ShrimBox, ShrimLine } from "./loading";


const OptionView = ({ router, children, loading, property = 'id', ...props }) => {

    router = router || useRouter();

    return (
        <CustomModal show={router.query[property]} close={() => router.push({})} {...props}>
            {
                loading ?
                    <Row>
                        <Col>
                            <ShrimBox />
                        </Col>
                        <Col>
                            <ShrimLine className={'py-4'} />
                        </Col>
                    </Row>
                    : children

            }
        </CustomModal>
    )
}

export default OptionView